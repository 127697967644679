import './style.scss';

import { FC, useMemo, useState } from 'react';

import { getBrandingLogoConfigsSelector } from '@mentorcliq/storage';
import { MQImage } from '@mentorcliq/ui';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAppIntl } from 'hooks/useAppIntl';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppViewport } from 'hooks/useAppViewport';

import MQAvatar from 'modules/MQAvatar';
import MQIcon from 'modules/MQIcon';
import MQNav from 'modules/MQNav';

import AppLink from 'router/AppLink';

const AppHeaderPreview: FC = () => {
  const intl = useAppIntl();
  const { isTabletState } = useAppViewport();
  const logoConfigs = useAppSelector(({ branding }) => getBrandingLogoConfigsSelector(branding));
  const [toggle, setToggle] = useState(false);
  const [expended, setExpended] = useState(false);
  const logo = useMemo(() => {
    if (isTabletState) {
      return {
        src: logoConfigs?.mobile?.fileHandle,
        branded: !!logoConfigs?.displayMobile,
      };
    }

    return {
      src: logoConfigs?.main?.fileHandle,
      branded: !!logoConfigs?.displayDefault,
    };
  }, [
    isTabletState,
    logoConfigs?.mobile?.fileHandle,
    logoConfigs?.main?.fileHandle,
    logoConfigs?.displayDefault,
    logoConfigs?.displayMobile,
  ]);

  return (
    <div className="app-header">
      <div className="app-header__section">
        <MQNav.Navbar
          className="app-header__navbar"
          expand="lg"
          onToggle={setToggle}
          onExpended={setExpended}
          ariaLabel="Nav Bar Preview"
        >
          <MQNav.Brand
            className={classNames('app-header__brand', {
              branded: logo.branded,
            })}
          >
            <MQNav.Link as={AppLink} to="#" className="app-header__logo">
              <MQImage
                src={logo.src}
                data-testid="header-logo"
                alt={intl.formatMessage({
                  defaultMessage: 'MentorcliQ',
                  id: 'app.header.preview.image.alt',
                  description: '[AppHeaderPreview] image alt',
                })}
              />
            </MQNav.Link>
          </MQNav.Brand>

          <MQNav className="app-header__navbar-menu">
            {expended && (
              <MQNav.Toggle data-testid="app-header-toggle" className="app-header__toggle">
                {toggle ? (
                  <MQIcon.Svg aria-label="Close Menu" className="app-header-toggle__close" size="2x" icon="times-alt" />
                ) : (
                  <MQIcon.Svg aria-label="Open Menu" className="app-header-toggle__open" size="2x" icon="bars" />
                )}
              </MQNav.Toggle>
            )}

            <MQNav.Collapse data-testid="app-header-collapse" className={'app-header__navbar-collapse'}>
              <MQNav>
                <MQNav.Link as={AppLink} to="#" className="app-header__navbar-link" data-testid="app-header-my-cliq">
                  <FormattedMessage defaultMessage="My CliQ" description="[Header] My CliQ" id="appHeader.my.cliQ" />
                </MQNav.Link>
                <MQNav.Dropdown variant="secondary" data-testid="app-header-learning-lab-dropdown" triggers={['hover']}>
                  <MQNav.DropdownToggle data-testid="app-header-learning-lab-tooggle">
                    <MQNav.Link
                      as={AppLink}
                      to="#"
                      className="app-header__navbar-link"
                      data-testid="app-header-learning-lab-link"
                      endIcon={<MQIcon.Svg icon="caret-down" />}
                    >
                      <FormattedMessage
                        defaultMessage="LearningLAB"
                        description="[Header] learning lab"
                        id="appHeader.learningLAB"
                      />
                    </MQNav.Link>
                  </MQNav.DropdownToggle>

                  <MQNav.DropdownMenu>
                    <MQNav.DropdownItem className="app-header__profile__menu-item">
                      <FormattedMessage
                        defaultMessage="MentorLAB"
                        description="[Header] learning lab"
                        id="appHeader.mentorLAB"
                      />
                    </MQNav.DropdownItem>
                    <MQNav.DropdownItem className="app-header__profile__menu-item">
                      <FormattedMessage
                        defaultMessage="InclusionLAB"
                        description="[Header] learning lab"
                        id="appHeader.inclusionLAB"
                      />
                    </MQNav.DropdownItem>
                    <MQNav.DropdownItem className="app-header__profile__menu-item">
                      <FormattedMessage
                        defaultMessage="LeadershipLAB"
                        description="[Header] learning lab"
                        id="appHeader.leadershipLAB"
                      />
                    </MQNav.DropdownItem>
                  </MQNav.DropdownMenu>
                </MQNav.Dropdown>
                <MQNav.Link
                  as={AppLink}
                  eventKey="resource-library"
                  to="#"
                  className="app-header__navbar-link"
                  data-testid="app-header-resource-library"
                >
                  <FormattedMessage
                    defaultMessage="Resource Library"
                    description="[Header] Resource Library"
                    id="appHeader.resource.library"
                  />
                </MQNav.Link>
              </MQNav>
            </MQNav.Collapse>
          </MQNav>

          <MQNav.Group>
            <MQNav.Dropdown className="app-header__profile" triggers={['click']}>
              <MQNav.DropdownToggle data-testid="app-header-profile-toggle" className="app-header__profile__toggle">
                <MQNav.Link
                  as={AppLink}
                  startIcon={
                    <MQAvatar.Image size="sm" title="John Doe" imageUrl={''} dataTestId="header-profile-picture" />
                  }
                  truncate
                  bolder
                >
                  {!expended && <strong className="app-header__profile-real-name">{'John Doe'}</strong>}
                </MQNav.Link>
              </MQNav.DropdownToggle>

              <MQNav.DropdownMenu data-testid="app-header-profile-menu" className="app-header__profile__menu">
                <MQNav.DropdownItem
                  data-testid="open-profile"
                  data-preview="disabled"
                  className="app-header__profile__menu-item"
                  startIcon={<MQIcon.Svg icon="user-circle" className="app-header__profile__menu-item-icon" />}
                >
                  <span className="app-header__profile__menu-item-title">
                    <FormattedMessage defaultMessage="My Profile" description="[Header] Profile button text" />
                  </span>
                </MQNav.DropdownItem>

                <MQNav.DropdownItem
                  data-testid="support"
                  data-preview="disabled"
                  className="app-header__profile__menu-item"
                  startIcon={<MQIcon.Svg size="lg" icon="life-ring" className="app-header__profile__menu-item-icon" />}
                >
                  <span className="app-header__profile__menu-item-title">
                    <FormattedMessage defaultMessage="Support" description="[Header] button text" />
                  </span>
                </MQNav.DropdownItem>
                <MQNav.DropdownItem
                  data-testid="privacy"
                  data-preview="disabled"
                  className="app-header__profile__menu-item"
                  startIcon={<MQIcon.Svg icon="user-shield" className="app-header__profile__menu-item-icon" />}
                >
                  <span className="app-header__profile__menu-item-title">
                    <FormattedMessage defaultMessage="Privacy" description="[Header] button text" />
                  </span>
                </MQNav.DropdownItem>
                <MQNav.DropdownItem
                  data-preview="disabled"
                  data-testid="sign-out"
                  className="app-header__profile__menu-item"
                  startIcon={<MQIcon.Svg icon="sign-out" className="app-header__profile__menu-item-icon" />}
                >
                  <span className="app-header__profile__menu-item-title">
                    <FormattedMessage defaultMessage="Log Out" description="[Header] button text" />
                  </span>
                </MQNav.DropdownItem>
              </MQNav.DropdownMenu>
            </MQNav.Dropdown>

            <MQNav.Divider direction="vertical" />

            <MQNav.Link
              as={AppLink}
              data-testid="app-header-language-toggle"
              className="app-header__language"
              startIcon={<MQIcon.Svg icon="globe-light" className="app-header__language-icon" />}
            >
              <span className="app-header__language-text">
                <FormattedMessage defaultMessage="EN" description="[Header] En language" />
              </span>
            </MQNav.Link>
          </MQNav.Group>
        </MQNav.Navbar>
      </div>
    </div>
  );
};

export default AppHeaderPreview;
