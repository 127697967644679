import { FC, ReactNode } from 'react';

import { IntlProvider } from 'react-intl';

import MQTypo from 'modules/MQTypo';

interface LocaleProviderProps {
  children?: ReactNode;
  locale: string;
  defaultLocale: string;
  messages: Record<string, string>;
}

const DEFAULT_RICH_TEXT_ELEMENTS = {
  strong: (...chunks: ReactNode[]) => <strong>{chunks}</strong>,
  highlighted: (...chunks: ReactNode[]) => <strong className="color-brand-indigo">{chunks}</strong>,
  br: () => <br />,
  i: (...chunks: ReactNode[]) => <i>{chunks}</i>,
  em: (...chunks: ReactNode[]) => <em>{chunks}</em>,
  pre: (...chunks: ReactNode[]) => <pre>{chunks}</pre>,
  u: (...chunks: ReactNode[]) => <u>{chunks}</u>,
  muted: (...chunks: ReactNode[]) => <MQTypo.Caption>{chunks}</MQTypo.Caption>,
};

const LocaleProvider: FC<LocaleProviderProps> = ({ children, locale, defaultLocale, messages }) => (
  <IntlProvider
    defaultRichTextElements={DEFAULT_RICH_TEXT_ELEMENTS}
    defaultLocale={defaultLocale}
    locale={locale}
    messages={messages}
  >
    {children}
  </IntlProvider>
);

export default LocaleProvider;
