import { ProgramDraftStepKeysEnum } from '@mentorcliq/storage';

import ROUTES from 'routes';

const SUPPORT_ARTICLE_PREFIX = 'https://mentorcliq.zendesk.com';

export const SUPPORT_ARTICLES_CONFIGS = {
  supervisorApproval: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11338174981901`,
  ssoIntegration: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9975865824013-Single-Sign-On-SSO-Integration`,
  ssoTesting: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9975865824013`,
  learningLabOverView: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11460083925389-MentorcliQ-Reports-Overview-`,
  collaborationTools: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11015882508813-Scheduling-Time-with-My-Mentoring-Partner`,
  matchingOverView: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/12099237165709-MentorcliQ-Matching-Overview-`,
  publishingMatches: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11103696136077-Publishing-Matches`,
  relationshipClosure: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11116533722637-Unmatching-and-Closing-Relationships`,
  intelligentMatching: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11225654849677`,
  viewParticipants: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11237731687821`,
  mentorCliQReportsOverview: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11460083925389-MentorcliQ-Reports-Overview-`,
  groupMatch: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9940325721101-Group-Matching`,
  adminMatching: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/12092114316813-Admin-Matching`,
  manageInvitations: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9940667534221-Managing-and-Removing-Pending-Invitations`,
  sendCommunicationsImmediately: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/10573051160077-Sending-a-Communication-Immediately`,
  manageParticipants: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9505074800525-Manage-Participants`,
  chatWithMatch: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11014384893453-Chatting-with-My-Mentoring-Partner-MentorcliQ-Chat-`,
  advancedFilters: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/27957220595341-Using-Advanced-Filters-in-Communications`,
};

export const SUPPORT_KEYWORDS_CONFIGS = {
  addons: {
    keywords: 'Add-ons',
    locations: [ROUTES.addonsConfiguration.data.path],
    wizardSteps: [],
  },
  support: {
    keywords: 'Support',
    locations: [ROUTES.support.data.path],
    wizardSteps: [],
  },
  vps: {
    keywords: 'VPS',
    locations: [ROUTES.profileVps.data.path, ROUTES.peopleEditProfileVps.data.path, ROUTES.verifyVps.data.path],
    wizardSteps: [],
  },
  privacy: {
    keywords: 'Privacy',
    locations: [ROUTES.privacy.data.path, ROUTES.acceptGdpr.data.path, ROUTES.gdprConsentView.data.path],
    wizardSteps: [],
  },
  branding: {
    keywords: 'Branding',
    locations: [ROUTES.brandingConfiguration.data.path],
    wizardSteps: [],
  },
  calendar: {
    keywords: 'Calendar',
    locations: [ROUTES.calendarConfiguration.data.path],
    wizardSteps: [],
  },
  platformConfiguration: {
    keywords: 'Configuration',
    locations: [ROUTES.platformConfiguration.data.path],
    wizardSteps: [],
  },
  platformInfo: {
    keywords: 'Info',
    locations: [ROUTES.platformInfoConfiguration.data.path],
    wizardSteps: [],
  },
  manageResources: {
    keywords: 'Manage Resources',
    locations: [ROUTES.managePlatformResources.data.path],
    wizardSteps: [],
  },
  profileDisplay: {
    keywords: 'Profile Display',
    locations: [ROUTES.profileDisplay.data.path],
    wizardSteps: [],
  },
  profileFields: {
    keywords: 'Profile Fields',
    locations: [ROUTES.profileFields.data.path],
    wizardSteps: [],
  },
  contentHelpDesk: {
    keywords: 'Content Helpdesk',
    locations: [ROUTES.helpdesk.data.path],
    wizardSteps: [],
  },
  matchPreferences: {
    keywords: 'Match Preferences',
    locations: [ROUTES.editPreferences.data.path],
    wizardSteps: [],
  },
  requestMatch: {
    keywords: 'Request Match',
    locations: [ROUTES.editRequestMatch.data.path],
    wizardSteps: [],
  },
  suggestMatch: {
    keywords: 'Suggest Match',
    locations: [ROUTES.editSuggestMatch.data.path],
    wizardSteps: [],
  },
  inclusionLab: {
    keywords: 'Inclusion Lab',
    locations: [ROUTES.inclusionLab.data.path],
    wizardSteps: [],
  },
  inclusionLabMasterClass: {
    keywords: 'Inclusion Lab Master Class',
    locations: [ROUTES.inclusionLabMasterClass.data.path],
    wizardSteps: [],
  },
  learningLab: {
    keywords: 'Learning Lab',
    locations: [ROUTES.labsList.data.path],
    wizardSteps: [],
  },
  leadershipLab: {
    keywords: 'Leadership Lab',
    locations: [ROUTES.leadershipLab.data.path],
    wizardSteps: [],
  },
  leadershipLabMasterClass: {
    keywords: 'Leadership Lab Master Class',
    locations: [ROUTES.leadershipLabMasterClass.data.path],
    wizardSteps: [],
  },
  leadershipLabQuickStart: {
    keywords: 'Leadership Lab Quick Start',
    locations: [ROUTES.leadershipLabQuickStart.data.path],
    wizardSteps: [],
  },
  mentorLab: {
    keywords: 'Mentor Lab',
    locations: [ROUTES.mentorLab.data.path],
    wizardSteps: [],
  },
  mentorLabQuickStart: {
    keywords: 'Mentor Lab Quick Start',
    locations: [ROUTES.mentorLabQuickStart.data.path],
    wizardSteps: [],
  },
  people: {
    keywords: 'people',
    locations: [ROUTES.peopleList.data.path],
    wizardSteps: [],
  },
  programDashBoard: {
    keywords: 'Program Dashboard',
    locations: [ROUTES.programDashboard.data.path],
    wizardSteps: [],
  },
  manageParticipants: {
    keywords: 'Manage Participants',
    locations: [ROUTES.manageParticipants.data.path],
    wizardSteps: [],
  },
  sendCommunications: {
    keywords: 'Send Communications',
    locations: [ROUTES.sendCommunication.data.path],
    wizardSteps: [],
  },
  manageInvitations: {
    keywords: 'Manage Invitations',
    locations: [ROUTES.manageInvitations.data.path],
    wizardSteps: [],
  },
  invitationsInvite: {
    keywords: 'Invite {role}',
    resolve: ({ role = 'Participant' }) => `Invite ${role}`,
    locations: [],
    wizardSteps: [],
  },
  manageMatches: {
    keywords: 'Manage Matches',
    locations: [ROUTES.manageMatches.data.path],
    wizardSteps: [],
  },
  detailedReports: {
    keywords: 'Detailed Reports',
    locations: [ROUTES.detailedReportsDashboard.data.path],
    wizardSteps: [],
  },
  programDataExport: {
    keywords: 'Export Data',
    locations: [ROUTES.platformReports.data.path],
    wizardSteps: [],
  },
  programReports: {
    keywords: 'Program Reports',
    locations: [ROUTES.programReports.data.path],
    wizardSteps: [],
  },
  quickQliQManualSend: {
    keywords: 'Send QuickcliQs',
    locations: [ROUTES.programSettingsQuickcliQs.data.path],
    wizardSteps: [],
  },
  designNewProgram: {
    keywords: 'Design new program',
    locations: [ROUTES.programWizardDraftCreate.data.path, ROUTES.programWizardWelcome.data.path],
    wizardSteps: [
      ProgramDraftStepKeysEnum.Intro,
      ProgramDraftStepKeysEnum.LaunchSummary,
      ProgramDraftStepKeysEnum.MatchIntro,
      ProgramDraftStepKeysEnum.MatchSummary,
      ProgramDraftStepKeysEnum.EngageIntro,
      ProgramDraftStepKeysEnum.EngageSummary,
      ProgramDraftStepKeysEnum.MeasureIntro,
      ProgramDraftStepKeysEnum.MeasureSummary,
      ProgramDraftStepKeysEnum.Summary,
    ],
  },
  configureProgram: {
    keywords: 'Configure Program',
    locations: [ROUTES.programWizardDraftEdit.data.path],
    wizardSteps: [],
  },
  addProgram: {
    keywords: 'Add Program',
    locations: [ROUTES.programWizardDraftPreview.data.path],
    wizardSteps: [],
  },
  programTemplates: {
    keywords: 'Program Templates',
    locations: [ROUTES.programWizardTemplates.data.path],
    wizardSteps: [],
  },
  programWizardWelcome: {
    keywords: 'Design New Program',
    locations: [],
    wizardSteps: [],
  },
  mentorFlixReports: {
    keywords: 'MentorFlix Reports',
    locations: [ROUTES.mentorFlixReport.data.path],
    wizardSteps: [],
  },
  exportData: {
    keywords: 'Export data',
    locations: [ROUTES.programExportData.data.path],
    wizardSteps: [],
  },
  resourceLibrary: {
    keywords: 'Resource Library',
    locations: [ROUTES.resourceLibrary.data.path],
    wizardSteps: [],
  },
  myCliQ: {
    keywords: 'My CliQ',
    locations: [ROUTES.myCliQ.data.path],
    wizardSteps: [],
  },
  editProfile: {
    keywords: 'Edit Profile',
    locations: [ROUTES.profileEdit.data.path],
    wizardSteps: [],
  },
  editProfilePicture: {
    keywords: 'Profile',
    locations: [ROUTES.profilePicture.data.path],
    wizardSteps: [],
  },
  openPrograms: {
    keywords: 'Open Programs',
    locations: [ROUTES.programs.data.path],
    wizardSteps: [],
  },
  timeLine: {
    keywords: 'Timeline',
    locations: [ROUTES.timeline.data.path],
    wizardSteps: [],
  },
  createProfile: {
    keywords: 'Profile',
    locations: [ROUTES.verifyProfile.data.path],
    wizardSteps: [],
  },
  platformWelcome: {
    keywords: 'Welcome',
    locations: [ROUTES.verifyWelcome.data.path],
    wizardSteps: [],
  },
  intelligentMatching: {
    keywords: 'Intelligent Matching',
    locations: [ROUTES.intelligentMatching.data.path],
    wizardSteps: [],
  },
  developingOthersReport: {
    keywords: 'Developing Others Report',
    locations: [ROUTES.developmentReportsOthers.data.path],
    wizardSteps: [],
  },
  individualDevelopmentReport: {
    keywords: 'Individual Development Report',
    locations: [ROUTES.developmentReportsPersonal.data.path],
    wizardSteps: [],
  },
  meeting: {
    keywords: 'Schedule Meeting',
    locations: [ROUTES.meeting.data.path],
    wizardSteps: [],
  },
  adminDashboard: {
    keywords: 'Admin Dashboard',
    locations: [ROUTES.adminDashboard.data.path],
    wizardSteps: [],
  },
  mentorFlix: {
    keywords: 'MentorFlix',
    locations: [ROUTES.mentorFlix.data.path],
    wizardSteps: [],
  },
  contentHelpdeskReport: {
    keywords: 'Content Helpdesk Report',
    locations: [ROUTES.helpdeskReport.data.path],
    wizardSteps: [],
  },
  resetStatuses: {
    keywords: 'Reset Participant Statuses',
    locations: [ROUTES.programSettingsPromptReset.data.path],
    wizardSteps: [],
  },
  resourceLibraryReport: {
    keywords: 'Resource Library Report',
    locations: [ROUTES.resourceLibraryReport.data.path],
    wizardSteps: [],
  },
  viewParticipants: {
    keywords: 'View Participants',
    locations: [ROUTES.viewParticipants.data.path],
    wizardSteps: [],
  },
  smartMatch: {
    keywords: 'Smart Match Participants',
    locations: [ROUTES.smartMatch.data.path],
    wizardSteps: [],
  },
  groupMatch: {
    keywords: 'Group Match Participants',
    locations: [ROUTES.groupMatch.data.path],
    wizardSteps: [],
  },
  conclude: {
    keywords: 'Leave Relationship',
    locations: [ROUTES.conclude.data.path],
    wizardSteps: [],
  },
  quickcliqSubmission: {
    keywords: 'QuickcliQ Submission',
    locations: [ROUTES.quickcliqSubmission.data.path],
    wizardSteps: [],
  },
  surveySubmission: {
    keywords: 'Survey Submission',
    locations: [ROUTES.quickcliqSubmission.data.path],
    wizardSteps: [],
  },
  approvals: {
    keywords: 'Approval',
    locations: [ROUTES.approvals.data.path],
    wizardSteps: [],
  },
  goals: {
    keywords: 'Program Goals',
    locations: [ROUTES.goalsList.data.path],
    wizardSteps: [],
  },
  goalEdit: {
    keywords: 'Edit Program Goal',
    locations: [ROUTES.editGoal.data.path],
    wizardSteps: [],
  },
  goalAdd: {
    keywords: 'Add Program Goal',
    locations: [ROUTES.addGoal.data.path],
    wizardSteps: [],
  },
  milestones: {
    keywords: 'Program Milestones',
    locations: [ROUTES.milestones.data.path],
    wizardSteps: [],
  },
  mentorLabMasterClass: {
    keywords: 'Mentor Lab Master Class',
    locations: [ROUTES.mentorLabMasterClass.data.path],
    wizardSteps: [],
  },
  inclusionLabQuickStart: {
    keywords: 'Inclusion Lab Quick Start',
    locations: [ROUTES.inclusionLabQuickStart.data.path],
    wizardSteps: [],
  },
  sso: {
    keywords: 'SSO',
    locations: [ROUTES.ssoConfigurationLogging.data.path, ROUTES.ssoConfigurationSetup.data.path],
    wizardSteps: [],
  },
  boostedCalculator: {
    keywords: 'ROI Calculator (Boost Version)',
    locations: [],
    wizardSteps: [],
  },
  freeCalculator: {
    keywords: 'ROI Calculator',
    locations: [],
    wizardSteps: [],
  },
  roiTrackingReport: {
    keywords: 'ROI Tracking Report',
    locations: [ROUTES.roiTrackingReport.data.path],
    wizardSteps: [],
  },
  roiProfileFieldSummary: {
    keywords: 'Profile Field Summary',
    locations: [ROUTES.reportsROICalculatorProfileFieldSummary.data.path],
    wizardSteps: [],
  },
  programName: {
    keywords: 'Program Name',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.Name],
  },
  programDescription: {
    keywords: 'Program Description',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.Description],
  },
  programLogo: {
    keywords: 'Program Logo',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.Logo],
  },
  customProgramEmail: {
    keywords: 'Custom Email Template',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.CustomEmailTemplate],
  },
  programEmailTemplate: {
    keywords: 'Program Email Template',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.ProgramEmailTemplate],
  },
  programSponsors: {
    keywords: 'Program Sponsors',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.Sponsors],
  },
  programAdministrators: {
    keywords: 'Program Administrators',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.Admins],
  },
  programPillarAndCategory: {
    keywords: 'Program Pillar Category',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.Category],
  },
  programRoles: {
    keywords: 'Program Roles',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.Roles],
  },
  invitationStyle: {
    keywords: 'Invitation Style',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.InvitationStyle, ProgramDraftStepKeysEnum.InvitationStyleRoles],
  },
  enrollmentNotification: {
    keywords: 'Enrollment',
    locations: [],
    wizardSteps: [
      ProgramDraftStepKeysEnum.EnrollmentNotification,
      ProgramDraftStepKeysEnum.EnrollmentNotificationRoles,
    ],
  },
  displayProgramDetails: {
    keywords: 'Display Program Details',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.DisplayProgramDetails],
  },
  eligibilityAgreement: {
    keywords: 'Eligibility Agreement',
    locations: [],
    wizardSteps: [
      ProgramDraftStepKeysEnum.EligibilityRequirements,
      ProgramDraftStepKeysEnum.EligibilityRequirementPerRole,
      ProgramDraftStepKeysEnum.EligibilityRequirementRoles,
    ],
  },
  supervisorApproval: {
    keywords: 'Supervisor Approval',
    locations: [],
    wizardSteps: [
      ProgramDraftStepKeysEnum.SupervisorApproval,
      ProgramDraftStepKeysEnum.SupervisorApprovalProfile,
      ProgramDraftStepKeysEnum.SupervisorApprovalRoles,
      ProgramDraftStepKeysEnum.SupervisorApprovalMessaging,
      ProgramDraftStepKeysEnum.SupervisorApprovalCustomMessage,
      ProgramDraftStepKeysEnum.SupervisorApprovalReminder,
    ],
  },
  mentorshipFocusAreas: {
    keywords: 'Mentorship Focus Areas',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.MentorshipFocusAreas],
  },
  programMatchPreferences: {
    keywords: 'Match Preferences',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.EnrollmentPreferences],
  },
  matchPreferencesDisplay: {
    keywords: 'Match Preferences Display',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.PreferencesDisplayConfigs],
  },
  relationshipStructure: {
    keywords: 'Relationship Structure',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.RelationshipStructure],
  },
  relationshipLength: {
    keywords: 'Relationship Length',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.RelationshipLength],
  },
  matchingStyles: {
    keywords: 'Matching Styles',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.MatchingStyles],
  },
  relationshipClosure: {
    keywords: 'Relationship Closure',
    locations: [],
    wizardSteps: [
      ProgramDraftStepKeysEnum.RelationshipClosure,
      ProgramDraftStepKeysEnum.RelationshipClosureNotification,
    ],
  },
  matchRules: {
    keywords: 'Match Rules',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.MatchRulesConfig],
  },
  allowMatchRules: {
    keywords: 'Allow Match Rules',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.AllowMatchRules],
  },
  matchingAlgorithm: {
    keywords: 'Matching Algorithm',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.MatchingAlgorithm],
  },
  selfMatch: {
    keywords: 'Self Match',
    locations: [],
    wizardSteps: [
      ProgramDraftStepKeysEnum.SelfMatchingRequestEmail,
      ProgramDraftStepKeysEnum.SelfMatchingReminder,
      ProgramDraftStepKeysEnum.SelfMatchingRoles,
      ProgramDraftStepKeysEnum.SelfMatchingExpiration,
      ProgramDraftStepKeysEnum.SelfMatchingConfigurations,
      ProgramDraftStepKeysEnum.SelfMatchingRequestExpiredEmail,
      ProgramDraftStepKeysEnum.SelfMatchingRequestDeclineEmail,
      ProgramDraftStepKeysEnum.SelfMatchingRequestAcceptanceEmail,
    ],
  },
  allowRelationshipGoals: {
    keywords: 'Allow Relationship Goals',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.AllowRelationshipGoals],
  },
  allowLeaveRelationship: {
    keywords: 'Allow Leave Relationship',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.LeaveRelationship],
  },
  programResources: {
    keywords: 'Program Resources',
    locations: [],
    wizardSteps: [
      ProgramDraftStepKeysEnum.AllowProgramResources,
      ProgramDraftStepKeysEnum.ProgramResourcesConfiguration,
    ],
  },
  matchNotificationCommunications: {
    keywords: 'Match Communications',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.MatchNotificationCommunications],
  },
  enrollmentCompletionMessagesPerRole: {
    keywords: 'Enrollment Completion Per Roles',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.EnrollmentCompletionMessagePerRole],
  },
  enrollmentCompletionMessaging: {
    keywords: 'Enrollment Completion Message',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.EnrollmentCompletionMessaging],
  },
  enrollmentCompletionRoles: {
    keywords: 'Enrollment Completion Roles',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.EnrollmentCompletionRoles],
  },
  scheduledCommunications: {
    keywords: 'Scheduled Communications',
    locations: [],
    wizardSteps: [ProgramDraftStepKeysEnum.ScheduledCommunications],
  },
  quickcliQs: {
    keywords: 'QuickcliQs',
    locations: [],
    wizardSteps: [
      ProgramDraftStepKeysEnum.QuickcliQs,
      ProgramDraftStepKeysEnum.QuickcliQNudge,
      ProgramDraftStepKeysEnum.QuickcliQNudgeMessage,
      ProgramDraftStepKeysEnum.QuickcliQReminder,
      ProgramDraftStepKeysEnum.QuickcliQFrequency,
      ProgramDraftStepKeysEnum.QuickcliQMessaging,
      ProgramDraftStepKeysEnum.QuickcliQRecipients,
      ProgramDraftStepKeysEnum.QuickcliQSummaryNotification,
      ProgramDraftStepKeysEnum.QuickcliQSummaryNotificationMessage,
      ProgramDraftStepKeysEnum.QuickcliQCustomMessage,
    ],
  },
  satisfactionSurveys: {
    keywords: 'Satisfaction',
    locations: [],
    wizardSteps: [
      ProgramDraftStepKeysEnum.SatisfactionSurveySchedule,
      ProgramDraftStepKeysEnum.SatisfactionSurveyReminder,
      ProgramDraftStepKeysEnum.SatisfactionSurveyEdit,
      ProgramDraftStepKeysEnum.SatisfactionSurvey,
      ProgramDraftStepKeysEnum.SatisfactionSurveyMessaging,
      ProgramDraftStepKeysEnum.SatisfactionSurveyRecipients,
      ProgramDraftStepKeysEnum.SatisfactionSurveyCustomMessage,
    ],
  },
};
