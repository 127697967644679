import './style.scss';

import { FC } from 'react';

import { FeatureTypeEnum } from '@mentorcliq/storage';
import { MQImage } from '@mentorcliq/ui';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import { STATIC_ASSETS } from 'definitions/assets';

import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppIntl } from 'hooks/useAppIntl';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

const AppFooter: FC = () => {
  const intl = useAppIntl();
  const { publicConfigs } = useAppConfigs();

  return (
    <footer className="app-footer">
      <div className="app-footer__section">
        <MQImage
          src={STATIC_ASSETS.images.base.poweredBy}
          alt={intl.formatMessage({
            defaultMessage: 'Powered by MentorcliQ',
            id: 'app.footer.image.alt',
            description: '[AppFooter] image alt',
          })}
        />
      </div>
      <div className="app-footer__section">
        {!!publicConfigs.platformConfigs?.customFooterConfiguration?.customFooter && (
          <div
            className="app-footer__custom"
            dangerouslySetInnerHTML={{
              __html: intl.dynamicMessage({
                defaultMessage: publicConfigs.platformConfigs?.customFooterConfiguration.customFooter,
              }),
            }}
          />
        )}
        {!!publicConfigs.platformConfigs?.customFooterConfiguration?.showDefaultPrivacyPolicy && (
          <AppLink to={ROUTES.privacy.data.path} data-testid="app-footer-privacy-policy">
            <FormattedMessage defaultMessage="Privacy Policy" description="Privacy Policy" />
          </AppLink>
        )}
        <PermissionWrapper features={{ value: [FeatureTypeEnum.Support] }}>
          <AppLink to={ROUTES.support.data.path}>
            <FormattedMessage defaultMessage="Support" description="Support" />
          </AppLink>
        </PermissionWrapper>
      </div>
    </footer>
  );
};

export default AppFooter;
