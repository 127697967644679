import './style.scss';

import { FC, useState, useEffect, useRef, useCallback } from 'react';

import { MQ_KEYBOARD_EVENT_KEYS } from '@mentorcliq/ui';
import classNames from 'classnames';

import { MQDropdownProps } from '../../types';

const MQDropdown: FC<MQDropdownProps> = ({
  children,
  className = '',
  variant = 'primary',
  show,
  triggers,
  uncontrolled = false,
  ...props
}) => {
  const [isShow, setIsShow] = useState(show || false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onClickOutside = useCallback(() => {
    if (!uncontrolled) {
      setIsShow(false);
    }
  }, [uncontrolled]);

  useEffect(() => {
    if (triggers?.includes('click')) {
      const handleOutsideClick = () => {
        onClickOutside();
      };

      document.addEventListener('mousedown', handleOutsideClick);

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [onClickOutside, variant, triggers]);

  const mouseEnter = () => {
    if (triggers?.includes('hover')) {
      setIsShow(true);
    }
  };

  const mouseLeave = () => {
    if (triggers?.includes('hover')) {
      setIsShow(false);
    }
  };

  const onClick = () => {
    if (triggers?.includes('click') && !uncontrolled) {
      setIsShow((prev) => !prev);
    }
  };

  return (
    <div
      data-testid="mq-dropdown"
      role="menu"
      tabIndex={0}
      {...props}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      ref={containerRef}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onClick={onClick}
      onKeyDown={(e) => {
        if (MQ_KEYBOARD_EVENT_KEYS.Enter.includes(e.key)) {
          e.stopPropagation();
          e.preventDefault();
          setIsShow((prev) => !prev);
        }
      }}
      onBlur={(event) => {
        if (containerRef.current && !containerRef.current.contains(event.relatedTarget)) {
          setIsShow(false);
        }
      }}
      className={classNames(['mq-nav-dropdown', className, variant], { show: isShow })}
    >
      {children}
    </div>
  );
};

export default MQDropdown;
