import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQTextProps } from '../types';

const MQText: FC<MQTextProps> = ({
  className = '',
  underline,
  italic = false,
  center = false,
  bold = false,
  size = '2x',
  nowrap = false,
  mixed = false,
  uppercase = false,
  children,
  dataTestId = 'mq-typo-text',
  variant,
  ...props
}) => (
  <span
    className={classNames([
      'mq-typo-text',
      `mq-typo-text-size-${size}`,
      {
        nowrap,
        bold,
        center,
        italic,
        uppercase,
        underline,
        mixed,
      },
      variant,
      className,
    ])}
    data-testid={dataTestId}
    {...props}
  >
    {children}
  </span>
);

export default MQText;
