import { APP_ENV_CONFIGS } from 'definitions/configs';

const CHART_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/charts`;
const ERROR_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/errors`;
const PROFILE_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/profile`;
const REGISTRATION_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/registration`;
const PROGRAM_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/program`;
const RESOURCES_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/resources`;
const BASE_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/base`;
const MATCHES_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/matches`;
const LAB_IMAGES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/lab`;
const FONTS_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/fonts`;
const AUDIO_FILES_URL = `${APP_ENV_CONFIGS.appStaticAssetsSrc}/audio`;

export const STATIC_ASSETS = {
  images: {
    charts: {
      areaChart: `${CHART_IMAGES_URL}/area_chart.svg`,
      categoryPieChart: `${CHART_IMAGES_URL}/category_pie_chart.svg`,
    },
    errors: {
      image403: `${ERROR_IMAGES_URL}/403.svg`,
      image404: `${ERROR_IMAGES_URL}/404.svg`,
      image429: `${ERROR_IMAGES_URL}/status429.svg`,
      image500: `${ERROR_IMAGES_URL}/exclamation-circle.svg`,
    },
    profile: {
      previewProfileAvatar: `${PROFILE_IMAGES_URL}/ProfilePreviewAvatar.jpg`,
      previewProfileAvatar1: `${PROFILE_IMAGES_URL}/ProfilePreviewAvatar1.webp`,
      previewProfileAvatar2: `${PROFILE_IMAGES_URL}/ProfilePreviewAvatar2.jpg`,
    },
    registration: {
      verifyWelcome: `${REGISTRATION_IMAGES_URL}/verifyWelcome.png`,
      requestMatch: `${REGISTRATION_IMAGES_URL}/requestMatch.png`,
      suggestMatch: `${REGISTRATION_IMAGES_URL}/requestMatch.png`,
      vps: `${REGISTRATION_IMAGES_URL}/vps.png`,
      circleCheck: `${REGISTRATION_IMAGES_URL}/circle-check.svg`,
      progressCheck: `${REGISTRATION_IMAGES_URL}/progress-check.svg`,
      pending: `${REGISTRATION_IMAGES_URL}/pending.svg`,
      mentorLab: `${REGISTRATION_IMAGES_URL}/mentorlab.png`,
      mentorLabLogoWhite: `${REGISTRATION_IMAGES_URL}/mentorlab-logo-white.png`,
      inclusionLab: `${REGISTRATION_IMAGES_URL}/inclusionlab.png`,
      inclusionLabLogWhite: `${REGISTRATION_IMAGES_URL}/inclusionlab-logo-white.png`,
      learningLab: `${REGISTRATION_IMAGES_URL}/LearningLab.png`,
      cliQiPedia: `${REGISTRATION_IMAGES_URL}/CliQiPedia.png`,
      resourceLibrary: `${REGISTRATION_IMAGES_URL}/ResourceLibrary.png`,
    },
    program: {
      congratulationsBanner: `${PROGRAM_IMAGES_URL}/program-congratulations-banner.jpg`,
      groupMentoring: `${PROGRAM_IMAGES_URL}/group-mentoring.svg`,
      publishDraftLoading: `${PROGRAM_IMAGES_URL}/publish-draft.gif`,
      matchPreferencesCard: `${PROGRAM_IMAGES_URL}/match-preferences.svg`,
      descriptionCard: `${PROGRAM_IMAGES_URL}/program-description.svg`,
      reportCard: `${PROGRAM_IMAGES_URL}/report.svg`,
      emailCardHeader: `${PROGRAM_IMAGES_URL}/email-header.svg`,
      logoCardHeader: `${PROGRAM_IMAGES_URL}/programs-header.svg`,
      reportCardHeader: `${PROGRAM_IMAGES_URL}/report-header.svg`,
      enrollmentFocusAreas: `${PROGRAM_IMAGES_URL}/focus-areas-enrollment.svg`,
      matchFocusAreas: `${PROGRAM_IMAGES_URL}/focus-areas-match.svg`,
      wizardIntro: `${PROGRAM_IMAGES_URL}/wizard-intro.png`,
      quickcliQFocusAreas: `${PROGRAM_IMAGES_URL}/focus-areas-quickcliq.svg`,
    },
    resources: {
      contentHelpdeskRequest: `${RESOURCES_IMAGES_URL}/content-helpdesk-request.png`,
      imageDefaultThumb: `${RESOURCES_IMAGES_URL}/imageDefaultThumb.jpg`,
      documentDefaultThumb: `${RESOURCES_IMAGES_URL}/documentDefaultThumb.jpg`,
      linkDefaultThumb: `${RESOURCES_IMAGES_URL}/linkDefaultThumb.jpg`,
      videoDefaultThumb: `${RESOURCES_IMAGES_URL}/videoDefaultThumb.jpg`,
    },
    lab: {
      learningLabBackground: `${LAB_IMAGES_URL}/learningLabBackground.png`,
      labOnDemandWatchIcon: `${LAB_IMAGES_URL}/labOnDemandWatchIcon.svg`,
      expertsJennLabin: `${LAB_IMAGES_URL}/expertsJennLabin.jpg`,
      mentorLabCardImage: `${LAB_IMAGES_URL}/mentorLabCardImage.svg`,
      mentorLabBottomBannerLogo: `${LAB_IMAGES_URL}/mentorLabBottomBannerLogo.svg`,
      mentorLabBottomBanner: `${LAB_IMAGES_URL}/mentorLabBottomBanner.png`,
      mentorLabBackground: `${LAB_IMAGES_URL}/mentorLabBackground.png`,
      mentorLabLaptop: `${LAB_IMAGES_URL}/mentorLabLaptop.png`,
      accreditedAchievement: `${LAB_IMAGES_URL}/accreditedAchievement.png`,
      expertsNicoleAnelas: `${LAB_IMAGES_URL}/expertsNicoleAnelas.jpg`,
      inclusionLabBackground: `${LAB_IMAGES_URL}/inclusionLabBackground.png`,
      inclusionLabBottomBanner: `${LAB_IMAGES_URL}/inclusionLabBottomBanner.png`,
      inclusionLabBottomBannerLogo: `${LAB_IMAGES_URL}/inclusionLabBottomBannerLogo.png`,
      inclusionLabCardImage: `${LAB_IMAGES_URL}/inclusionLabCardImage.svg`,
      inclusionLabDocumentIcon: `${LAB_IMAGES_URL}/inclusionLabDocumentIcon.png`,
      inclusionLabLaptop: `${LAB_IMAGES_URL}/inclusionLabLaptop.png`,
      inclusionLabLearningOutcomesImplicitBias: `${LAB_IMAGES_URL}/inclusionLabLearningOutcomesImplicitBias.svg`,
      inclusionLabLearningOutcomesMentoringAllyship: `${LAB_IMAGES_URL}/inclusionLabLearningOutcomesMentoringAllyship.svg`,
      inclusionLabMasterClassBatteryIcon: `${LAB_IMAGES_URL}/inclusionLabMasterClassBatteryIcon.svg`,
      inclusionLabMasterClassCirclesIcon: `${LAB_IMAGES_URL}/inclusionLabMasterClassCirclesIcon.svg`,
      inclusionLabMasterClassHeartCommentsIcon: `${LAB_IMAGES_URL}/inclusionLabMasterClassHeartCommentsIcon.svg`,
      inclusionLabMasterClassHouseIcon: `${LAB_IMAGES_URL}/inclusionLabMasterClassHouseIcon.svg`,
      inclusionLabMasterClassLogo: `${LAB_IMAGES_URL}/inclusionLabMasterClassLogo.svg`,
      inclusionLabMasterClassRecordingBatteryIcon: `${LAB_IMAGES_URL}/inclusionLabMasterClassRecordingBatteryIcon.svg`,
      inclusionLabMasterClassRecordingCirclesIcon: `${LAB_IMAGES_URL}/inclusionLabMasterClassRecordingCirclesIcon.svg`,
      inclusionLabMasterClassRecordingHeartIcon: `${LAB_IMAGES_URL}/inclusionLabMasterClassRecordingHeartIcon.svg`,
      inclusionLabMasterClassRecordingHouseIcon: `${LAB_IMAGES_URL}/inclusionLabMasterClassRecordingHouseIcon.svg`,
      inclusionLabMasterClassRecordingsFirstWeekThumb: `${LAB_IMAGES_URL}/inclusionLabMasterClassRecordingsFirstWeekThumb.png`,
      inclusionLabMasterClassRecordingsFourthWeekThumb: `${LAB_IMAGES_URL}/inclusionLabMasterClassRecordingsFourthWeekThumb.png`,
      inclusionLabMasterClassRecordingsSecondWeekThumb: `${LAB_IMAGES_URL}/inclusionLabMasterClassRecordingsSecondWeekThumb.png`,
      inclusionLabMasterClassRecordingsThirdWeekThumb: `${LAB_IMAGES_URL}/inclusionLabMasterClassRecordingsThirdWeekThumb.png`,
      inclusionLabMasterClassTopSectionImage: `${LAB_IMAGES_URL}/inclusionLabMasterClassTopSectionImage.png`,
      inclusionLabQuickStartLogo: `${LAB_IMAGES_URL}/inclusionLabQuickStartLogo.svg`,
      inclusionLabQuickStartOnDemandVideoThumb: `${LAB_IMAGES_URL}/inclusionLabQuickStartOnDemandVideoThumb.jpg`,
      inclusionLabQuickStartTopSectionImage: `${LAB_IMAGES_URL}/inclusionLabQuickStartTopSectionImage.png`,
      inclusionLabWayToLearnClock: `${LAB_IMAGES_URL}/inclusionLabWayToLearnClock.svg`,
      inclusionLabWayToLearnStudent: `${LAB_IMAGES_URL}/inclusionLabWayToLearnStudent.svg`,
      leadershipLabBackground: `${LAB_IMAGES_URL}/leadershipLabBackground.png`,
      leadershipLabBottomBanner: `${LAB_IMAGES_URL}/leadershipLabBottomBanner.png`,
      leadershipLabBottomBannerLogo: `${LAB_IMAGES_URL}/leadershipLabBottomBannerLogo.svg`,
      leadershipLabCardImage: `${LAB_IMAGES_URL}/leadershipLabCardImage.svg`,
      leadershipLabDocumentIcon: `${LAB_IMAGES_URL}/leadershipLabDocumentIcon.png`,
      leadershipLabLaptop: `${LAB_IMAGES_URL}/leadershipLabLaptop.png`,
      leadershipLabLearningOutcomesBuildResilience: `${LAB_IMAGES_URL}/leadershipLabLearningOutcomesBuildResilience.svg`,
      leadershipLabLearningOutcomesGrowthMindset: `${LAB_IMAGES_URL}/leadershipLabLearningOutcomesGrowthMindset.svg`,
      leadershipLabMasterClassCommentsIcon: `${LAB_IMAGES_URL}/leadershipLabMasterClassCommentsIcon.svg`,
      leadershipLabMasterClassHandshakeIcon: `${LAB_IMAGES_URL}/leadershipLabMasterClassHandshakeIcon.svg`,
      leadershipLabMasterClassHandStarIcon: `${LAB_IMAGES_URL}/leadershipLabMasterClassHandStarIcon.svg`,
      leadershipLabMasterClassLogo: `${LAB_IMAGES_URL}/leadershipLabMasterClassLogo.svg`,
      leadershipLabMasterClassMagnifyingGlassIcon: `${LAB_IMAGES_URL}/leadershipLabMasterClassMagnifyingGlassIcon.svg`,
      leadershipLabMasterClassRecordingCommentsIcon: `${LAB_IMAGES_URL}/leadershipLabMasterClassRecordingCommentsIcon.svg`,
      leadershipLabMasterClassRecordingHandshakeIcon: `${LAB_IMAGES_URL}/leadershipLabMasterClassRecordingHandshakeIcon.svg`,
      leadershipLabMasterClassRecordingMagnifyingGlassIcon: `${LAB_IMAGES_URL}/leadershipLabMasterClassRecordingMagnifyingGlassIcon.svg`,
      leadershipLabMasterClassRecordingsFirstWeekThumb: `${LAB_IMAGES_URL}/leadershipLabMasterClassRecordingsFirstWeekThumb.png`,
      leadershipLabMasterClassRecordingsFourthWeekThumb: `${LAB_IMAGES_URL}/leadershipLabMasterClassRecordingsFourthWeekThumb.png`,
      leadershipLabMasterClassRecordingsSecondWeekThumb: `${LAB_IMAGES_URL}/leadershipLabMasterClassRecordingsSecondWeekThumb.png`,
      leadershipLabMasterClassRecordingStarHandIcon: `${LAB_IMAGES_URL}/leadershipLabMasterClassRecordingStarHandIcon.svg`,
      leadershipLabMasterClassRecordingsThirdWeekThumb: `${LAB_IMAGES_URL}/leadershipLabMasterClassRecordingsThirdWeekThumb.png`,
      leadershipLabMasterClassTopSectionImage: `${LAB_IMAGES_URL}/leadershipLabMasterClassTopSectionImage.png`,
      leadershipLabQuickStartLogo: `${LAB_IMAGES_URL}/leadershipLabQuickStartLogo.svg`,
      leadershipLabQuickStartOnDemandVideoThumb: `${LAB_IMAGES_URL}/leadershipLabQuickStartOnDemandVideoThumb.jpg`,
      leadershipLabQuickStartTopSectionImage: `${LAB_IMAGES_URL}/leadershipLabQuickStartTopSectionImage.png`,
      leadershipLabWayToLearnClock: `${LAB_IMAGES_URL}/leadershipLabWayToLearnClock.svg`,
      leadershipLabWayToLearnStudent: `${LAB_IMAGES_URL}/leadershipLabWayToLearnStudent.svg`,
      mentoringBookAchievement: `${LAB_IMAGES_URL}/mentoringBookAchievement.png`,
      mentorLabDocumentIcon: `${LAB_IMAGES_URL}/mentorLabDocumentIcon.png`,
      mentorLabLearningOutcomesMentorBehavior: `${LAB_IMAGES_URL}/mentorLabLearningOutcomesMentorBehavior.svg`,
      mentorLabLearningOutcomesStoryToConnect: `${LAB_IMAGES_URL}/mentorLabLearningOutcomesStoryToConnect.svg`,
      mentorLabLearningOutcomesTrustThroughout: `${LAB_IMAGES_URL}/mentorLabLearningOutcomesTrustThroughout.svg`,
      mentorLabMasterClassArrowRightIcon: `${LAB_IMAGES_URL}/mentorLabMasterClassArrowRightIcon.png`,
      mentorLabMasterClassBlocksIcon: `${LAB_IMAGES_URL}/mentorLabMasterClassBlocksIcon.png`,
      mentorLabMasterClassLogo: `${LAB_IMAGES_URL}/mentorLabMasterClassLogo.svg`,
      mentorLabMasterClassRecordingArrowRightIcon: `${LAB_IMAGES_URL}/mentorLabMasterClassRecordingArrowRightIcon.png`,
      mentorLabMasterClassRecordingBlocksIcon: `${LAB_IMAGES_URL}/mentorLabMasterClassRecordingBlocksIcon.png`,
      mentorLabMasterClassRecordingRocketIcon: `${LAB_IMAGES_URL}/mentorLabMasterClassRecordingRocketIcon.png`,
      mentorLabMasterClassRecordingScrollIcon: `${LAB_IMAGES_URL}/mentorLabMasterClassRecordingScrollIcon.png`,
      mentorLabMasterClassRecordingsFirstWeekThumb: `${LAB_IMAGES_URL}/mentorLabMasterClassRecordingsFirstWeekThumb.jpg`,
      mentorLabMasterClassRecordingsFourthWeekThumb: `${LAB_IMAGES_URL}/mentorLabMasterClassRecordingsFourthWeekThumb.jpg`,
      mentorLabMasterClassRecordingsSecondWeekThumb: `${LAB_IMAGES_URL}/mentorLabMasterClassRecordingsSecondWeekThumb.jpg`,
      mentorLabMasterClassRecordingsThirdWeekThumb: `${LAB_IMAGES_URL}/mentorLabMasterClassRecordingsThirdWeekThumb.jpg`,
      mentorLabMasterClassRocketIcon: `${LAB_IMAGES_URL}/mentorLabMasterClassRocketIcon.png`,
      mentorLabMasterClassScrollIcon: `${LAB_IMAGES_URL}/mentorLabMasterClassScrollIcon.png`,
      mentorLabMasterClassTopSectionImage: `${LAB_IMAGES_URL}/mentorLabMasterClassTopSectionImage.png`,
      mentorLabQuickStartLogo: `${LAB_IMAGES_URL}/mentorLabQuickStartLogo.svg`,
      mentorLabQuickStartOnDemandVideoThumb: `${LAB_IMAGES_URL}/mentorLabQuickStartOnDemandVideoThumb.jpg`,
      mentorLabQuickStartTopSectionImage: `${LAB_IMAGES_URL}/mentorLabQuickStartTopSectionImage.png`,
      mentorLabWayToLearnClock: `${LAB_IMAGES_URL}/mentorLabWayToLearnClock.svg`,
      mentorLabWayToLearnStudent: `${LAB_IMAGES_URL}/mentorLabWayToLearnStudent.svg`,
    },
    matches: {
      acceptIcon: `${MATCHES_IMAGES_URL}/Accept.svg`,
      expiredIcon: `${MATCHES_IMAGES_URL}/Expired.svg`,
      declineIcon: `${MATCHES_IMAGES_URL}/Decline.svg`,
    },
    base: {
      logo: `${BASE_IMAGES_URL}/logo.png`,
      logoHighQuality: `${BASE_IMAGES_URL}/logoHighQuality.png`,
      highFive: `${BASE_IMAGES_URL}/high-five.png`,
      largeLogo: `${BASE_IMAGES_URL}/largeLogo.jpg`,
      confettiCannon: `${BASE_IMAGES_URL}/confetti-cannon.gif`,
      atom: `${BASE_IMAGES_URL}/atom.png`,
      appStoreIcon: `${BASE_IMAGES_URL}/app_store_image.jpeg`,
      playStoreIcon: `${BASE_IMAGES_URL}/google_play_image.png`,
      successIcon: `${BASE_IMAGES_URL}/success.png`,
      poweredBy: `${BASE_IMAGES_URL}/powered-by-mentorcliq.svg`,
      smallLogo: `${BASE_IMAGES_URL}/smallLogo.png`,
    },
  },
  docs: {
    resources: {
      closurePlanForm: `${APP_ENV_CONFIGS.projectResourcesPath}/Closure Plan form.pdf`,
      gettingStartedForMentees: `${APP_ENV_CONFIGS.projectResourcesPath}/Getting Started for Mentees.pdf`,
      gettingStartedForMentors: `${APP_ENV_CONFIGS.projectResourcesPath}/Getting Started for Mentors.pdf`,
      mentoringPartnershipAgreement: `${APP_ENV_CONFIGS.projectResourcesPath}/Mentoring Partnership Agreement Form.pdf`,
      midCycleCheckIn: `${APP_ENV_CONFIGS.projectResourcesPath}/Mid-cycle check in.pdf`,
    },
  },
  fonts: {
    robotoRegular: `${FONTS_URL}/roboto-regular-unicode.txt`,
    robotoBold: `${FONTS_URL}/roboto-bold-unicode.txt`,
    notoRegular: `${FONTS_URL}/noto-regular-unicode.txt`,
    notoBold: `${FONTS_URL}/noto-bold-unicode.txt`,
    thaiRegular: `${FONTS_URL}/thai-regular-unicode.txt`,
    thaiBold: `${FONTS_URL}/thai-bold-unicode.txt`,
  },
  audio: {
    message: `${AUDIO_FILES_URL}/message.mp3`,
  },
};

export const APP_IMAGE_ALTS = {
  profilePicture: 'Chat Room Profile Picture',
};
