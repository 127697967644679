import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes, LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  program: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId`,
    },
  }),
  programDashboard: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/dashboard`,
      exact: true,
    },
  }),
  launch: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch`,
    },
  }),
  invitations: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/invitations`,
    },
  }),
  manageInvitations: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/invitations/list`,
    },
  }),
  manageInvitationsPending: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/invitations/list/pending`,
      exact: true,
    },
  }),
  manageInvitationsAll: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/invitations/list/all`,
      exact: true,
    },
  }),
  invitationsInvite: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/invitations/invite/:roleId?`,
      exact: true,
    },
  }),
  approvals: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/approvals`,
    },
  }),
  approvalsApproved: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/approvals/approved`,
      exact: true,
    },
  }),
  approvalsPending: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/approvals/pending`,
      exact: true,
    },
  }),
  approvalsDeclined: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/approvals/declined`,
      exact: true,
    },
  }),
  approvalsAll: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/launch/approvals/all`,
      exact: true,
    },
  }),
  match: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/match`,
    },
  }),
  viewParticipants: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/match/participants`,
      exact: true,
    },
  }),
  adminMatches: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/match/:enrollmentId/admin-matches',
      exact: true,
    },
  }),
  smartMatch: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/match/smart-match',
      exact: true,
    },
  }),
  groupMatch: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/match/group-match',
    },
  }),
  intelligentMatching: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/match/intelligent-matching',
    },
  }),
  intelligentMatchingUpcoming: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/match/intelligent-matching/upcoming`,
      exact: true,
    },
  }),
  intelligentMatchingPast: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/match/intelligent-matching/past`,
      exact: true,
    },
  }),
  intelligentMatchingAdd: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/match/intelligent-matching/add`,
      exact: true,
    },
  }),
  intelligentMatchingEdit: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/match/intelligent-matching/edit/:intelligentMatchId`,
      exact: true,
    },
  }),
  manageMatches: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePredefinedUnmatchReasons],
        },
      },
    },
    data: {
      path: `/program/:programId/match/manage-matches`,
    },
  }),
  manageMatchesActive: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePredefinedUnmatchReasons],
        },
      },
    },
    data: {
      path: `/program/:programId/match/manage-matches/active`,
      exact: true,
    },
  }),
  manageMatchesPending: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePredefinedUnmatchReasons],
        },
      },
    },
    data: {
      path: `/program/:programId/match/manage-matches/pending`,
      exact: true,
    },
  }),
  manageMatchesFinished: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePredefinedUnmatchReasons],
        },
      },
    },
    data: {
      path: `/program/:programId/match/manage-matches/finished`,
      exact: true,
    },
  }),
  manageMatchesDeclined: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePredefinedUnmatchReasons],
        },
      },
    },
    data: {
      path: `/program/:programId/match/manage-matches/declined`,
      exact: true,
    },
  }),
  manageMatchesExpired: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePredefinedUnmatchReasons],
        },
      },
    },
    data: {
      path: `/program/:programId/match/manage-matches/expired`,
      exact: true,
    },
  }),
  manageMatchesAll: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePredefinedUnmatchReasons],
        },
      },
    },
    data: {
      path: `/program/:programId/match/manage-matches/all`,
      exact: true,
    },
  }),
  engage: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/engage`,
    },
  }),
  manageParticipants: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageParticipants, PermissionsEnum.ManagePredefinedRetireReasons],
          strict: true,
        },
      },
    },
    data: {
      path: `/program/:programId/engage/manage-participants`,
    },
  }),
  manageParticipantsActive: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageParticipants, PermissionsEnum.ManagePredefinedRetireReasons],
          strict: true,
        },
      },
    },
    data: {
      path: `/program/:programId/engage/manage-participants/active`,
      exact: true,
    },
  }),
  manageParticipantsRetired: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageParticipants, PermissionsEnum.ManagePredefinedRetireReasons],
          strict: true,
        },
      },
    },
    data: {
      path: `/program/:programId/engage/manage-participants/retired`,
      exact: true,
    },
  }),
  measure: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/measure',
    },
  }),
  detailedReportsDashboard: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/measure/detailed-reports/dashboard',
      exact: true,
    },
  }),
  detailedReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/measure/detailed-reports',
    },
  }),
  incompleteGoalsReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/incomplete-goals`,
      exact: true,
    },
  }),
  relationshipSatisfactionSurvey: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/measure/detailed-reports/relationship-satisfaction',
      exact: true,
    },
  }),
  relationshipSatisfactionReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/detailed-reports/relationship-satisfaction/inactive`,
      exact: true,
    },
  }),
  programSatisfaction: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/measure/detailed-reports/program-satisfaction',
      exact: true,
    },
  }),
  programResourceLibraryReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/measure/detailed-reports/resource-library',
      exact: true,
    },
  }),
  programSatisfactionInactivity: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/measure/detailed-reports/program-satisfaction/inactive',
      exact: true,
    },
  }),
  quickcliQsReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageQuickcliQsReport],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/detailed-reports/quickcliqs`,
    },
  }),
  milestonesReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageMilestonesReport],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/detailed-reports/milestones`,
      exact: true,
    },
  }),
  relationshipGoals: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/detailed-reports/relationship-goals`,
    },
  }),
  relationshipGoalsReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/detailed-reports/relationship-goals/goals`,
      exact: true,
    },
  }),
  relationshipGroupWithoutGoalsReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/detailed-reports/relationship-goals/groups-without-goals`,
      exact: true,
    },
  }),
  relationshipException: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageRelationshipException],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/detailed-reports/relationship-exception`,
      exact: true,
    },
  }),
  programROICalculator: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        features: {
          value: [FeatureTypeEnum.RoiReport],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/roi-calculator`,
      exact: true,
    },
  }),
  programReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/report`,
    },
  }),
  programProgressReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/report/progress`,
      exact: true,
    },
  }),
  programInvestmentReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/report/investment`,
      exact: true,
    },
  }),
  programParticipationReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/report/participation`,
      exact: true,
    },
  }),
  programSatisfactionReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/report/program-satisfaction`,
      exact: true,
    },
  }),
  programRelationshipSatisfaction: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/report/relationship-satisfaction`,
      exact: true,
    },
  }),
  programSettings: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/settings`,
    },
  }),
  programSettingsQuickcliQs: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.SendQuickcliQs],
        },
      },
    },
    data: {
      path: `/program/:programId/settings/send-quickcliqs`,
      exact: true,
    },
  }),
  programSettingsPromptReset: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageResetProfileAndEnrollments],
        },
      },
    },
    data: {
      path: `/program/:programId/settings/prompt-reset`,
    },
  }),
  programSettingsPromptResetProfile: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageResetProfileAndEnrollments],
        },
      },
    },
    data: {
      exact: true,
      path: `/program/:programId/settings/prompt-reset/profile`,
    },
  }),
  programSettingsPromptResetEnrollment: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageResetProfileAndEnrollments],
        },
      },
    },
    data: {
      exact: true,
      path: `/program/:programId/settings/prompt-reset/enrollment`,
    },
  }),
  programExportData: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/export-data`,
      exact: true,
    },
  }),
  sendCommunicationMain: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCommunication],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/send-communication/participants`,
    },
  }),
  sendCommunication: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCommunication],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/send-communication/:activeTabId?`,
    },
  }),
  sendCommunicationParticipants: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCommunication],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/send-communication/participants`,
      exact: true,
    },
  }),
  sendCommunicationActiveGroups: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCommunication],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/send-communication/active-group`,
      exact: true,
    },
  }),
  sendCommunicationCustom: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCommunication],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/send-communication/custom`,
      exact: true,
    },
  }),
  sendCommunicationCompose: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCommunication],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/send-communication/:activeTabId/compose`,
      exact: true,
    },
  }),
  sendCommunicationPreview: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCommunication],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/send-communication/:activeTabId/preview`,
      exact: true,
    },
  }),
  programLearningLabReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/program/:programId/measure/detailed-reports/learning-lab',
      exact: true,
    },
  }),
  surveyBuilder: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSurveyBuilder],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/survey-builder`,
    },
  }),
  surveyBuilderList: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSurveyBuilder],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/survey-builder/list`,
      exact: true,
    },
  }),
  surveyBuilderAdd: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSurveyBuilder],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/survey-builder/add`,
      exact: true,
    },
  }),
  surveyBuilderEdit: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSurveyBuilder],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/survey-builder/edit/:surveyId`,
      exact: true,
    },
  }),
  surveyBuilderCopy: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSurveyBuilder],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/survey-builder/copy/:surveyId`,
      exact: true,
    },
  }),
  surveyBuilderReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSurveyBuilder],
        },
      },
    },
    data: {
      path: `/program/:programId/engage/survey-builder/:surveyId/view-results`,
      exact: true,
    },
  }),
};
